import React from 'react';
import { useSelector } from 'react-redux';

import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import {
  breederTransportOptionsQueryDefinition,
  singleBreederProfileResourceDefinition
} from 'dpl/shared/utils/tptConnectResourceDefinitions';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';
import SkeletonBlock from 'dpl/common/design_system/SkeletonBlock';
import BreederProfileFollowButtonContainer from 'dpl/containers/BreederProfile/Show/BreederProfileFollowButtonContainer';
import { FILTER_LOCATION_NAME } from 'dpl/constants/query_params';
import { isNumeric } from 'dpl/shared/form/utils';
import useToggle from 'dpl/shared/hooks/useToggle';
import TransportationModal from 'dpl/modals/TransportationModal';

import CertifiedBreederBadge from './CertifiedBreederBadge';
import useBreederSearchUpdatesExp from '../hooks/useBreederSearchUpdatesExp';

function getSubheader({ breeder, location }) {
  const { distance, city_state: cityState } = location || {};
  const inLocationOrPeriod = cityState ? ` in ${cityState}.` : '.';

  if (!isNumeric(distance) || distance === 0 || distance > 150) {
    return `Raised by ${breeder.display_name}${inLocationOrPeriod}`;
  }

  return `${distance} ${distance === 1 ? 'mile' : 'miles'} away${inLocationOrPeriod}`;
}

// header for exp_breeder_search_updates
function ExperimentHeader() {
  const [isTransportModalOpen, toggleIsTransportModalOpen] = useToggle(false);
  const breederProfileId = useSelector(({ server }) => server.breederProfileId);

  const locName = useSelector(
    ({ queryParams }) => queryParams[FILTER_LOCATION_NAME]
  );

  const { value: breeder, isFetching: isFetchingBreeder } = useResourceQuery(
    singleBreederProfileResourceDefinition(breederProfileId)
  );

  const {
    value: {
      data: {
        location,
        has_dropoff: hasDropoff,
        delivery_methods: deliveryMethods,
        pickup_options: pickupOptions
      }
    },
    isFetching: isFetchingTransportOptions
  } = useResourceQuery(
    breederTransportOptionsQueryDefinition(breederProfileId, { locName })
  );

  if (isFetchingBreeder) {
    return (
      <div className="BreederProfileHeaderSkeleton container-xxl bg-sand flex flex-column items-center pt6 pb4">
        <SkeletonBlock className="br-100 mb4" height="64px" width="64px" />
        <SkeletonBlock className="font-24 mb2" height="1.2em" width="70%" />
        <SkeletonBlock className="font-14 mb1" height="1.4em" width="16em" />
        <SkeletonBlock className="font-14" height="1.4em" width="20em" />
      </div>
    );
  }

  return (
    <div className="BreederProfileHeader container-xxl bg-sand tc pt6 pb4">
      <div className="dib relative mb2">
        <UserAvatar
          size="lg"
          user={{
            id: breeder.user.id,
            initials: breeder.initials,
            profile_photo_url: breeder.avatar_profile_photo_url
          }}
        />
        <CertifiedBreederBadge width="18px" height="18px" />
      </div>
      <h1 className="font-24 lh-title mb1">
        {breeder.display_name}
        <br />
        {breeder.name}
      </h1>
      {isFetchingTransportOptions ? (
        <div className="flex flex-column items-center gap-4">
          <SkeletonBlock className="font-14" height="1.4em" width="16em" />
          <SkeletonBlock className="font-14" height="1.4em" width="20em" />
        </div>
      ) : (
        <div className="font-14 lh-body secondary">
          <p>{getSubheader({ breeder, location })}</p>
          {hasDropoff && location.distance > 50 && (
            <>
              <button
                type="button"
                className="underline"
                onClick={toggleIsTransportModalOpen}
              >
                Puppy pick-up and drop-off available
              </button>
              {isTransportModalOpen && (
                <TransportationModal
                  breederFirstName={breeder.user.first_name}
                  deliveryMethods={deliveryMethods}
                  pickupOptions={pickupOptions}
                  distance={location.distance}
                  onCloseClick={toggleIsTransportModalOpen}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default function BreederProfileHeader() {
  const { breederDisplayName } = useSelector(({ server }) => server);

  const { isExperiment } = useBreederSearchUpdatesExp();

  if (isExperiment) {
    return <ExperimentHeader />;
  }

  return (
    // moved this out of show.html.erb file to setup experiment
    // but can always move this back afterwards depending on experiment result
    <div className="container-xxl pt3 pt8-md">
      <div className="flex flex-wrap items-center gap-16 pv3 pv8-md">
        <h1 className="BreederProfileShowPage__displayName font-28 md:font-40 lh-title">
          {breederDisplayName}
        </h1>
        <div className="hidden md:block" style={{ minWidth: '6em' }}>
          <BreederProfileFollowButtonContainer />
        </div>
      </div>
    </div>
  );
}
